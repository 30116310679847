// i18next-extract-mark-ns-start products-vectis-solvers

import { VECTIS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductVectisSolversPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const solvers = getImage(data.solvers.childImageSharp.gatsbyImageData);
	const gasoline = getImage(data.gasoline.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">

					{solvers && (
						<p>
							<GatsbyImage image={solvers} alt="VECTIS solvers" />
						</p>
					)}

					<Trans i18nKey="content">
						<p>
							Internal Combustion Engine analysis sets challenges in a
							number of areas of multi-scale computational modelling. These
							include the requirement for intricate complex geometry motion
							modelling to accurately resolve chemical kinetics. VECTIS
							addresses these areas by providing novel models and methods
							that deliver outstanding accuracy within a computational time
							appropriate for engineering design tasks :
						</p>

						<ul>
							<li>Multi-domain fluid/solid coupled solver utilizing Realis's
								generalized SIMPLE algorithm with full flow/turbulence
								coupling for accurate resolution of complex flows</li>
							<li>Advanced RANS and LES turbulence models including
								Realis Turbulence Scale Adapted (TSA) and Time Scale
								Bounded (TSB) RANS models imp</li>
							<li>Unique accurate face to face tracking Lagrangian solver for complex
								multi-component and multi-fluid sprays modelling</li>
							<li>Accurate multi-component and multi-fluid wall-film
								solver delivering enhanced accuracy on moving boundaries,
								such as valves and pistons</li>
							<li>Realis Fidelity Cascade approach based on a
								combination of Realis Two-Zone Flamelet (RTZF)
								combustion model, advanced level-set for flame
								tracking, Combustion Progress Variable (CPV) tabulated
								kinetics approach and Realis Detailed Direct Kinetics</li>
							<li>R2DK combustion models enabling fast workflows for
								all types of analysis from performance to detailed
								kinetics emissions and abnormal combustion</li>
							<li>Detailed spark-ignition models based on Realis Dynamic
								Discrete Particle Ignition Kernel (DDPIK) flame kernel evolution approach</li>
						</ul>
					</Trans>

					{gasoline && (
						<p>
							<GatsbyImage image={gasoline} alt="VECTIS gasoline DI combustion" />
							<Trans i18nKey="small"><small>Predicted spray, wall-film formation and combustion in a gasoline DI engine</small></Trans>
						</p>
					)}
				</div>

				<Aside>
					<Aside.Widget
						title="Capabilities and applications"
						className="widget__sibling__pages">
						<AssetList>
							{VECTIS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductVectisSolversPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-vectis-solvers", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS.jpg" }) {
		...imageBreaker
	}
	solvers: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS_Solvers.png" }) {
		...imageBreaker
	}
	gasoline: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS_Gasoline_DI.png" }) {
		...imageBreaker
	}
}
`;

